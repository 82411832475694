export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const BSC_EXPLORER = {
  56: "https://bscscan.com",
  97: "https://testnet.bscscan.com",
};

export const STAKING_CONTRACT_ADDRESS = {
  56: "0x796f012052AB9AF44c81D53A1A8368D4D5cff8FB",
  106: "0x796f012052AB9AF44c81D53A1A8368D4D5cff8FB",
};

export const BSC_PLSPAD_ADDRESS = {
  56: "0x8a74bc8c372bc7f0e9ca3f6ac0df51be15aec47a",
  106: "0x8a74bc8c372bc7f0e9ca3f6ac0df51be15aec47a"
};

export const POOL_INTERVAL = 15000;

export const BACKEND_URI = "https://app.pulsepad.io";

// export const BACKEND_URI = "https://t.nftlaunch.network";
export const NODE_URI = {
  56: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
  ],
  97: ["https://data-seed-prebsc-1-s2.binance.org:8545/"],
  106: ["https://mainnet.velas.com/rpc"],
  // 1: ['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded']
};

export const KYC_BACK_END_URI = "https://bscpad.com";

export const chainIdsSupport = [56];

export const defaultChainId = 56;
// process.env.REACT_APP_MODE === "MAINNET" ? 56 : 97;

export const showKYC = 1000.0;
