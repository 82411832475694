import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { actGetListProjects } from "../../redux/action/user";
import ClosedProjectsComponent from "./components/ClosedProjectsComponent";
import OpeningProjectsComponent from "./components/OpeningProjectsComponent";
import WaitingProjectsComponent from "./components/WaitingProjectsComponent";
import './index.css';
import { useLatestBlockNumber } from "../../hook/useState";

const ProjectsPage = () => {
    const dispatch = useDispatch();
    const latestBlock = useLatestBlockNumber();

    useEffect(() => {
        dispatch(actGetListProjects())
    }, [latestBlock])

    return <div className="projects-page">
        <Container>
            <div className="pd-banner-wrapper">
                <img src="/images/uz7.svg" className="tab-content-bg left-bg" style={{ left: '-30px', top: '-100px' }} />
                <img src="/images/uz2.svg" className="tab-content-bg right-bg" style={{ top: '200px', right: '-40px' }} />
                <div className="pd-banner">
                    <img src="/images/banner.svg" alt="" style={{ maxWidth: '100%' }} />
                </div>
                <div className="pd-slogan">
                    Bringing You the <span>Future</span> of Ethereum — Today!
                </div>
                <Row className="justify-content-center">
                    <Col lg="6">
                        <div className="text-center">PulsePad is the premier IDO platform for projects building on PulseChain — a new Ethereum sister chain designed to provide an idyllic environment for building powerful new applications, while simultaneously reducing the load on the Ethereum network.</div>
                    </Col>
                </Row>
                <div className="intro__nav intro__wrapper justify-content-center mt-5 d-none d-md-flex">
                    <div className="intro-btns flex-wrap me-0 justify-content-center" style={{ rowGap: '20px' }}>
                        <a href="https://pulsepad.io/" target="_blank" className="btn-pd me-3">Homepage</a>
                        <a href="https://staking.pulsepad.io" target="_blank" className="btn-pd">Staking</a>
                    </div>
                </div>

            </div>
            <OpeningProjectsComponent />
            <WaitingProjectsComponent />
            <ClosedProjectsComponent />
        </Container>
    </div>
}
export default ProjectsPage;