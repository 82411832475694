import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from "lodash";
import BlockUi from "react-block-ui";
import Header from "../components/Header";
import Footer from "../components/Footer";
import $ from 'jquery';
import "react-block-ui/style.css";

export const PrivateLayout = ({ children, ...rest }) => {
    const showBlockUI = useSelector((state) =>
        get(state, "utils.blocking", false)
    );

    $(window).on('scroll', function () {
        setHeaderClass();
    })

    const setHeaderClass = () => {
        var sc = $(window).scrollTop();
        if (sc > 0) {
            $('#PPNavbar').addClass('pd-header-fixed')
        } else {
            $('#PPNavbar').removeClass('pd-header-fixed')
        }
    }

    useEffect(() => {
        setHeaderClass();
    })

    return (
        <>
            <BlockUi tag="div" blocking={showBlockUI}>
                <Header />
                {children}
                <Footer />
            </BlockUi>
        </>
    );
};