import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import exactMath from 'exact-math';
import { ACTION_CONST, ROUTES } from "../constants";
import { helpers } from "../../shared/utils";
import $ from 'jquery';
import { useIsConnectWallet, usePadTokenBalance, useWeb3Utils } from "./hook/useSate";
import { useKycStatus, useLatestBlockNumber, useStakeAmount } from "../../hook/useState";
import { useActiveWeb3React } from "../../hook";
import { getKYC } from "../../redux/services/kyc.api";
import { showKYC } from "../../_configs";
import { getAmountPadToken, getStakeAmountWallet } from "../utils/contractHelpers";

const Header = () => {
  const dispatch = useDispatch();
  const { account, library, chainId } = useActiveWeb3React()
  const walletUtils = useWeb3Utils()
  // const pulespadBalance = usePadTokenBalance();


  const latestBlock = useLatestBlockNumber()
  const stakeAmount = useStakeAmount()
  const kycStatus = useKycStatus();
  const [enableKYC, setEnableKYC] = useState(false);

  const [bscStaked, setBSCStaked] = useState(0);
  const [bscPadBalance, setBSCPadBalance] = useState(0);
  const [ethStaked, setETHStaked] = useState(0);
  const [ethPadBalance, setETHPadBalance] = useState(0);

  useEffect(() => {
    if (account) {
      // getStakeAmountWallet(56, account).then(result => {
      //   console.log("staked bsc==>", result);
      //   setBSCStaked(result)
      // })
      getStakeAmountWallet(106, account).then(result => {
        // console.log("staked velas==>", result);
        setETHStaked(result);
      })

      getAmountPadToken(56, account).then(balance => {
        // console.log("balance bsc==>", balance);
        setBSCPadBalance(balance)
      })
      getAmountPadToken(106, account).then(balance => {
        // console.log("balance velas==>", balance);
        setETHPadBalance(balance)
      })
    }



  }, [account, latestBlock])
  //set balance
  // useEffect(() => {

  //   if (walletUtils && account) {

  //     //get bnb balance
  //     // walletUtils.web3.eth.getBalance(account).then(balance => {

  //     //   dispatch({
  //     //     type: ACTION_CONST.GET_BNB_BALANCE,
  //     //     data: exactMath.div(balance, 10 ** 18)
  //     //   })
  //     // }).catch(err => {
  //     //   console.log(err);
  //     // })

  //     // walletUtils.getTokenPadBalance().then(data => {

  //     //   dispatch({
  //     //     type: ACTION_CONST.GET_PLSPAD_WALLET_BALANCE,
  //     //     data: data
  //     //   })
  //     // })

  //     //get staking wallet Info
  //     // walletUtils.getStakingInfoWallet().then(data => {
  //     //   dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
  //     // });


  //     //Set interval

  //   }

  // }, [latestBlock, walletUtils, account]);


  //check show hide KYC status
  useEffect(() => {
    if (!account) {
      return;
    }
    if (( Number(bscPadBalance) + Number(ethStaked) + Number(ethPadBalance)) >= showKYC) {

      setEnableKYC(true)

      getKYCAddress(account)

    } else {
      setEnableKYC(false)
    }

  }, [chainId, account, stakeAmount, latestBlock])


  //get kyc
  const getKYCAddress = (address) => {
    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleTabChange = () => {
    $('.navbar-toggler').trigger('click');
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <a className="navbar-brand" href="https://pulsepad.io">
            <img src="/images/logo.png" alt="PulsePad" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarApp"
            aria-controls="navbarApp" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarApp">
            <ul className="navbar-nav me-auto mb-3 mt-3 mt-lg-0 mb-lg-0 ms-lg-5">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.PROJECTS} onClick={handleTabChange}>Projects</NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://staking.pulsepad.io" target="_blank" onClick={handleTabChange}>Staking</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://bridge.pulsepad.io" target="_blank" >Bridge</a>
              </li>
            </ul>
            <ul className={account ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>
              {
                !account ?
                  <li className="nav-item me-2">
                    <div className="d-flex align-items-center justify-content-end">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#connectWalletModal"} className="btn-connect">
                        <span>Connect Wallet</span>
                      </a>
                    </div>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a href="#" data-bs-toggle="modal" data-bs-target={"#walletModal"} className="btn-pd">
                        <span><b>{helpers.formatTransactionHash(account, 4, 4)}</b> - {helpers.formatNumberDownRoundWithExtractMax(bscPadBalance, 4)} $PLSPAD</span>
                      </a>
                    </li>
                    {enableKYC &&
                      <>
                        {
                          kycStatus === 'START' &&
                          <li className="nav-item">
                            <button className="btn-pd btn-kyc-start px-4 ms-2" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-pencil-plus me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span >KYC</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'PENDING' &&
                          <li className="nav-item">
                            <button className="btn-pd btn-kyc-pending px-4 ms-2" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-exclamation-thick me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span>KYC</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'APPROVED' &&
                          <li className="nav-item">
                            <button className="btn-pd btn-kyc-success px-4 ms-2">
                              <i className="mdi mdi-check-bold me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span>KYC</span>
                            </button>
                          </li>
                        }
                        {
                          kycStatus === 'ERROR' &&
                          <li className="nav-item">
                            <button className="btn-pd btn-kyc-failed px-4 ms-2" onClick={() => handleOnclickKyc()}>
                              <i className="mdi mdi-alert-octagon-outline me-1" style={{ fontSize: '24px', lineHeight: 1 }}></i>
                              <span>KYC</span>
                            </button>
                          </li>
                        }
                      </>
                    }

                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
