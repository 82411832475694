import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actAlertClear, actAlertLinkClear } from "./redux/action";
import { history } from "./shared/utils/history";
import { PrivateRoute } from "./shared/routes/PrivateRoute";
import { ROUTES } from "./shared/constants";
import PrivacyPolicyPage from "./pages/privacy-policy";
import CookiesPolicyPage from "./pages/cookies-policy";
import TermOfUsePage from "./pages/terms-conditions";
import ProjectsPage from "./pages/projects";
import ProjectDetailPage from "./pages/project-detail";
import Web3ReactManager from "./components/Web3ReactManager";
import AppUpdater from "./hook/updater";

const App = () => {
  const { utils, alert } = useSelector((state) => state);
  const dispatch = useDispatch();
  toggleAlert(true);

  window.setTimeout(function () {
    toggleAlert(false);
    if (alert && JSON.stringify(alert) != JSON.stringify({})) {
      dispatch(actAlertClear());
    }
    if (utils.alertLink && utils.alertLink.type.trim().length > 0) {
      dispatch(actAlertLinkClear());
    }
  }, 5000);


  return (
    <>
      {alert.message && (
        <div id="toast-container" className="toast-top-right">
          <div id="toast-id" className={`toast toast-${alert.type}`}>
            <div className="toast-message">
              <div>
                <div>{alert.message}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {utils.alertLink.url.length > 0 && (
        <a
          onClick={() => dispatch(actAlertLinkClear())}
          href={utils.alertLink.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="toast-container" className="toast-top-right">
            <div
              id="toast-id"
              className={`toast toast-${utils.alertLink.type}`}
            >
              <div className="toast-message">
                <div>
                  <div>{utils.alertLink.message}</div>
                </div>
              </div>
            </div>
          </div>
        </a>
      )}

      <Router history={history}>
        <Web3ReactManager>
        <AppUpdater />
          <Switch>
            <PrivateRoute
              exact
              path={ROUTES.PROJECTS}
              component={ProjectsPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.PROJECT_DETAIL}
              component={ProjectDetailPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.PRIVACY_POLICY}
              component={PrivacyPolicyPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.COOKIES_POLICY}
              component={CookiesPolicyPage}
            />
            <PrivateRoute
              exact
              path={ROUTES.TERM_OF_USES}
              component={TermOfUsePage}
            />

            <Redirect from='**' to={ROUTES.PROJECTS} />
          </Switch>
        </Web3ReactManager>
      </Router>
    </>
  );
};

export default App;
function toggleAlert(isShow) {
  var alertMsg = document.getElementById("toast-container");
  var alertIdMsg = document.getElementById("toast-id");

  // Prevent link alert is dismiss
  if (alertMsg && alertMsg.parentElement.nodeName === "A") {
    return;
  }

  if (alertMsg) {
    alertMsg.style.display = isShow ? "block" : "none";
  }
  if (alertIdMsg) {
    alertIdMsg.style.opacity = isShow ? 1 : 0;
  }
}
